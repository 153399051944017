import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import { request } from "./request"
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  getMutationMode,
  useDeleteWithConfirmController,
  useResourceContext,
  useRedirect,
} from 'ra-core';
import { Confirm, Button } from 'ra-ui-materialui'
import { API_URL } from "./App"

export const DeleteWithConfirmButton = (
  props
) => {
  const {
      basePath,
      classes: classesOverride,
      className,
      confirmTitle = 'ra.message.delete_title',
      confirmContent = 'ra.message.delete_content',
      icon = defaultIcon,
      label = 'ra.action.delete',
      mutationMode,
      onClick,
      record,
      redirect = 'list',
      onSuccess,
      onFailure,
      undoable,
      token,
      ...rest
  } = props;
  const classes = useStyles(props);
  const redirection = useRedirect()
  const resource = useResourceContext(props);
  const mode = getMutationMode(mutationMode, undoable);

  const {
      open,
      loading,
      handleDialogOpen,
      handleDialogClose,
  } = useDeleteWithConfirmController({
      record,
      redirect,
      basePath,
      mutationMode: mutationMode || mode,
      onClick,
      onSuccess,
      onFailure,
      resource,
  });

  return (
      <Fragment>
          <Button
              onClick={handleDialogOpen}
              label={label}
              className={classnames(
                  'ra-delete-button',
                  classes.deleteButton,
                  className
              )}
              key="button"
              {...rest}
          >
              {icon}
          </Button>
          <Confirm
            isOpen={open}
            loading={loading}
            title={confirmTitle}
            content={confirmContent}
            translateOptions={{
              name: resource.split("_").join(":"),
              id: record ? record.id : "",
            }}
            onConfirm={e => {
              handleDialogClose(e)
              request(
                API_URL,
                "POST",
                "v0/pipelines/execute",
                {
                  project: "blueforest:dropin/editor",
                  id: "blueforest:dropin/ui/editor:v1:delete",
                },
                { collection: resource, id: record.id },
                token,
              ).then(() => {
                redirection("list", basePath || `/${resource}`)
              })
            }}
            onClose={handleDialogClose}
          />
      </Fragment>
  );
};

const defaultIcon = <ActionDelete />;

const useStyles = makeStyles(
  theme => ({
      deleteButton: {
          color: theme.palette.error.main,
          '&:hover': {
              backgroundColor: fade(theme.palette.error.main, 0.12),
              // Reset on mouse devices
              '@media (hover: none)': {
                  backgroundColor: 'transparent',
              },
          },
      },
  }),
  { name: 'RaDeleteWithConfirmButton' }
);

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
  undoable: PropTypes.bool,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};
