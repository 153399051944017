import * as RA from "react-admin"
import { Chip } from '@material-ui/core'

// eslint-disable-next-line
export const generateFields = (fields, edit, prefix) => Object.keys(fields).map((fieldKey, fieldIndex) => {
  const generate = (key, field, index) => {
    const validate = () => {
      const tests = []
      if(field.required) {
        tests.push(value => {
          let valid = true
          if(typeof value === "undefined" || value === null) {
            valid = false
          } else if(field.type === "image") {
            valid = Object.keys(value).length !== 0
          } else {
            //console.log(field, value)
          }
          return valid ? undefined : "Champ obligatoire"
        })
      }
      return tests
    }

    // eslint-disable-next-line
    switch(field.type) {

      case "quantity":
        if(edit) {
          return <RA.NumberInput source={key} label={field.name} validate={validate()} key={index} disabled={field.locked}/>
        }
        return <RA.NumberField source={key} label={field.name} key={index}/>

      case "text":
        if(edit) {
          return <RA.TextInput
            source={key}
            label={field.name}
            validate={validate()}
            key={index}
            multiline={field.options?.multiline}
            disabled={field.locked}
          />
        }
        return <RA.TextField source={key} label={field.name} key={index}/>

      case "boolean":
        if(edit) {
          return <RA.BooleanInput source={key} label={field.name} validate={validate()} defaultChecked={false} key={index} disabled={field.locked}/>
        }
        return <RA.BooleanField source={key} label={field.name} key={index}/>

      case "date":
        if(edit) {
          return <RA.DateTimeInput
            source={key}
            label={field.name}
            validate={validate()}
            key={index}
            disabled={field.locked}
          />
        }
        return <RA.DateField showTime source={key} label={field.name} key={index}/>

      case "choices":
        const choices = Object.keys(field.options.values).map(id => ({ id, name: field.options.values[id] }))
        if(edit && !field.locked) {
          if(field.options.many) {
            return <RA.SelectArrayInput
              key={index}
              source={key}
              choices={choices}
              label={field.name}
              disabled={field.locked}
            />
          }
          return <RA.SelectInput
            key={index}
            source={key}
            choices={choices}
            label={field.name}
          />
        }
        if(field.options.many) {
          const ChoicesView = subProps => {
            return <RA.Labeled label={field.name}>
              <div>
                {subProps.record[key] && subProps.record[key].map((record, rKey) => {
                  return <div style={{ marginBottom: 10 }}>
                    <Chip
                      key={rKey}
                      label={field.options.values[record]}
                    />
                  </div>
                })}
              </div>
            </RA.Labeled>
          }
          return <ChoicesView key={index}/>
        }
        const selectField = <RA.SelectField
          key={index}
          source={key}
          choices={choices}
          label={field.name}
        />
        return field.locked ? <RA.Labeled key={index} label={field.name} children={selectField}/> : selectField

      case "email":
        if(edit) {
          return <RA.TextInput source={key} label={field.name} validate={validate()} key={index} disabled={field.locked}/>
        }
        return <RA.EmailField source={key} label={field.name} key={index}/>

      case "file":
        if(edit && !field.locked) {
          return <RA.FileInput source={key} label={field.name} key={index} validate={validate()}>
            <RA.FileField target="_blank">
              Fichier existant
            </RA.FileField>
          </RA.FileInput>
        }
        const Text = props => {
          if(typeof props.record[key] !== "string") return ""
          const split = props.record[key].split("&")
          return <a href={props.record[key]} target="_blank" rel="noreferrer" children={split[split.length - 1].slice(5)}/>
        }
        return <RA.Labeled label={field.name} key={index}>
          <Text/>
        </RA.Labeled>

      case "image":
        if(edit && !field.locked) {
          return <RA.ImageInput source={key} label={field.name} key={index} validate={validate()}>
            <RA.ImageField source="url"/>
          </RA.ImageInput>
        }
        return <RA.ImageField source={key+".url"} label={field.name} key={index}/>

      case "list":
        const listFields = Object.keys(field.format).map((listField, index) => {
          return generate(listField, field.format[listField], index)
        })
        if(edit) {
          return <RA.ArrayInput source={key} label={field.name} key={index} defaultValue={[]} validate={validate()} disabled={field.locked}>
            <RA.SimpleFormIterator children={listFields}/>
          </RA.ArrayInput>
        }
        return <RA.ArrayField source={key} label={field.name} key={index}>
          <RA.Datagrid children={listFields}/>
        </RA.ArrayField>

      case "object":
        return <RA.Labeled label={field.name} key={index}>
          <div
            style={{ backgroundColor: "#f3f3f3", padding: "10px 15px", margin: 2, display: "flex", flexDirection: "column" }}
            className="object-field"
            key={index}
            children={generateFields(field.format, edit, fieldKey)}
          />
        </RA.Labeled>

      case "item":
        if(field.options.many) {
          if(edit) {
            return <RA.ReferenceArrayInput
              source={key}
              reference={field.options.collection}
              label={field.name}
              key={index}
              validate={validate()}
              defaultValue={[]}
              perPage={1000}
              allowEmpty
            >
              <RA.SelectArrayInput optionText={field.options.name} disabled={field.locked}/>
            </RA.ReferenceArrayInput>
          }
          return <RA.ReferenceArrayField
            source={key}
            reference={field.options.collection}
            label={field.name}
            key={index}
          >
            <RA.SingleFieldList>
              <RA.ChipField source={field.options.name} onClickCapture={e => {
                window.location = e.target.parentNode.href + "/show"
                e.preventDefault()
              }}/>
            </RA.SingleFieldList>
          </RA.ReferenceArrayField>
        }
        if(edit && !field.locked) {
          return <RA.ReferenceInput
            source={key}
            reference={field.options.collection}
            label={field.name}
            key={index}
            perPage={10000}
            validate={validate()}
            allowEmpty
          >
            <RA.AutocompleteInput optionText={field.options.name}/>
          </RA.ReferenceInput>
        }
        const view = <RA.ReferenceField
          source={key}
          reference={field.options.collection}
          label={field.name}
          key={index}
          link="show"
        >
          <RA.ChipField source={field.options.name}/>
        </RA.ReferenceField>
        return field.locked ? <RA.Labeled label={field.name} key={index} children={view}/> : view
    }
  }

  const rootField = fields[fieldKey]
  if(prefix) fieldKey = prefix + "." + fieldKey
  let output = generate(fieldKey, rootField, fieldIndex)

  if(edit) {
    output = <div key={fieldIndex}>{output}</div>
  } else if(prefix) {
    return <RA.Labeled key={fieldIndex} label={rootField.name} children={output}/>
  }

  return output
})
