import * as React from "react"
import * as RA from "react-admin"
import * as MUI from "@material-ui/core"

export const Menu = props => {
  return <div style={{ marginTop: 8, width: 180 }}>
    {props.projects.map((project, pIndex) => <div key={pIndex}>
      <MUI.Typography variant="h5" style={{ marginBottom: 5, marginLeft: 10 }} children={project.name}/>
      {project.collections.map((collection, index) => <RA.MenuItemLink
        to={`/${collection.id}`}
        primaryText={collection.name}
        dense={true}
        key={index}
        selected={window.location.hash.slice(2).split("/")[0] === collection.id}
        sidebarIsOpen={true}
      />)}
      {project.tools && <RA.MenuItemLink
        to={`/tools?${project.id}`}
        primaryText={"Outils"}
        dense={true}
        selected={window.location.hash === `#/tools?${project.id}`}
        sidebarIsOpen={true}
      />}
    </div>)}
  </div>
}
