import * as React from "react"
import * as RA from "react-admin"
import { Route } from 'react-router-dom'
import * as MUI from "@material-ui/core"
import { i18nProvider } from "./i18n"
import { generateFields } from "./fields"
import { Menu } from "./menu"
import { request } from "./request"
import { DeleteWithConfirmButton } from "./DeleteWithConfirmButton"

export const API_URL = { url: "https://api.mblocation.dropin.cloud" }
const ACCOUNT_URL = { url: "https://account.mblocation.dropin.cloud" }
export const API_EDITOR_URL = { url: "https://api.account.mblocation.dropin.cloud" }

const SaveButtonToolbar = tProps => {
  return <RA.Toolbar {...tProps}>
    <RA.SaveButton redirect="show"/>
  </RA.Toolbar>
}

const sanitizeRestProps = ({
  basePath,
  className,
  hasEdit,
  hasList,
  resource,
  ...rest
}) => rest

const ShowToolbar = ({ className, hasDelete, token, actions, ...rest }) => {
  const { basePath, record } = RA.useShowContext(rest)
  const { hasEdit } = RA.useResourceDefinition(rest)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return <RA.TopToolbar className={className} {...sanitizeRestProps(rest)}>
    {actions && <div style={{ marginRight: 10 }}>
      <MUI.Button aria-controls="actions-menu" aria-haspopup="true" size="small" onClick={handleClick}>
        Actions
      </MUI.Button>
      <MUI.Menu
        id="actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions.map((action, index) => <MUI.MenuItem
          key={"action"+index}
          children={action.name}
          onClick={() => {
            handleClose()
            let params = {}
            let run = true
            if(typeof action.params !== "undefined") {
              Object.keys(action.params).forEach(param => {
                if(!run) return
                const input = window.prompt(action.params[param])
                if(input && window.confirm(`Confirmez-vous la valeur "${input}" ?`)) {
                  params[param] = input
                } else {
                  run = false
                }
              })
            }
            if(run) {
              request(
                API_URL,
                "POST",
                "v0/pipelines/execute",
                {
                  project: action.project,
                  id: action.id,
                },
                {
                  collection: rest.resource,
                  id: rest.data.id,
                  params,
                },
                token,
              ).then(() => {
                document.location.reload()
              })
            }
          }}
        />)}
      </MUI.Menu>
    </div>}
    {hasEdit && <RA.EditButton basePath={basePath} record={record}/>}
    {hasDelete && <DeleteWithConfirmButton token={token} basePath={basePath} record={record}/>}
  </RA.TopToolbar>
}

const extractFile = (data, path) => {
  //console.log(data, path)
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const pathSplit = path.split(".")
      resolve({
        base64: reader.result.replace("data:", "").replace(/^.+,/, ""),
        type: pathSplit[pathSplit.length - 1],
      })
    }
    reader.readAsDataURL(data)
  })
  /*const xhr = new XMLHttpRequest()
  xhr.onload = () => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(xhr.response)
  }
  xhr.open("GET", params.data[dataKey].rawFile.url)
  xhr.responseType = "blob"
  xhr.send()*/
}

const App = () => {
  const [ data, setData ] = React.useState({ projects: [] })
  const token = document.cookie.match('(^|;)\\s*token\\s*=\\s*([^;]+)')?.pop() || ''
  React.useEffect(() => {
    if(!token) {
      window.location = ACCOUNT_URL.url + "/?referrer="+window.location
    } else {
      request(
        API_URL,
        "POST",
        "v0/pipelines/execute",
        {
          project: "blueforest:dropin/editor",
          id: "blueforest:dropin/ui/editor:v1:retrieve",
        },
        undefined,
        token,
      ).then(response => {
        setData(response)
      })
    }
  // eslint-disable-next-line
  }, [])
  return <RA.Admin
    i18nProvider={i18nProvider}
    customRoutes={[
      <Route
        path="/tools"
        component={() => {
          const id = window.location.hash.slice(8)
          const project = data.projects.reduce((found, current) => {
            console.log("found", current.id, id, typeof found === "undefined")
            if(typeof found === "undefined" && current.id === id) {
              return current
            }
            return found
          }, undefined)
          return <div>
            {project.tools.map((tool, index) => <div key={index}>
              <a
                href={API_URL.url
                  + `/v0/pipelines/execute?project=${project.id}&id=${tool.id}&token=${token}`}
                target="_blank"
                rel="noreferrer"
              >
                <button>{tool.name}</button>
              </a>
            </div>)}
          </div>
        }}
      />,
    ]}
    dataProvider={{
      getList: (collection, params) => {
        //console.log("getList", collection, params)
        return request(
          API_URL,
          "POST",
          "v0/pipelines/execute",
          {
            project: "blueforest:dropin/editor",
            id: "blueforest:dropin/ui/editor:v1:list",
          },
          {
            collection,
            pagination: params.pagination,
            sort: params.sort,
            search: params.filter.search,
          },
          token,
        )
      },
      getMany:  (collection) => {
        //console.log("getMany", collection)
        return request(
          API_URL,
          "POST",
          "v0/pipelines/execute",
          {
            project: "blueforest:dropin/editor",
            id: "blueforest:dropin/ui/editor:v1:list",
          },
          { collection },
          token,
        )
      },
      getOne: (collection, params) => {
        //console.log("getOne", collection, params)
        return request(
          API_URL,
          "POST",
          "v0/pipelines/execute",
          {
            project: "blueforest:dropin/editor",
            id: "blueforest:dropin/ui/editor:v1:item",
          },
          { collection, id: params.id },
          token,
        )
      },
      update: (collection, params) => {
        //console.log("update", collection, params)
        return Object.keys(params.data).reduce((promise, dataKey) => promise.then(() => {
          if(params.data[dataKey] && params.data[dataKey].rawFile) {
            return extractFile(
              params.data[dataKey].rawFile,
              params.data[dataKey].rawFile.path,
            ).then(file => {
              params.data[dataKey] = file
            })
          }
        }), Promise.resolve()).then(() => {
          return request(
            API_URL,
            "POST",
            "v0/pipelines/execute",
            {
              project: "blueforest:dropin/editor",
              id: "blueforest:dropin/ui/editor:v1:update",
            },
            { collection, id: params.id, data: params.data },
            token,
          ).then(() => {
            return Promise.resolve({ data: params.data })
          })
        })
      },
      create: (collection, params) => {
        //console.log("create", collection, params)
        return Object.keys(params.data).reduce((promise, dataKey) => promise.then(() => {
          if(params.data[dataKey] && params.data[dataKey].rawFile) {
            return extractFile(
              params.data[dataKey].rawFile,
              params.data[dataKey].rawFile.path,
            ).then(file => {
              params.data[dataKey] = file
            })
          }
        }), Promise.resolve()).then(() => {
          return request(
            API_URL,
            "POST",
            "v0/pipelines/execute",
            {
              project: "blueforest:dropin/editor",
              id: "blueforest:dropin/ui/editor:v1:create",
            },
            { collection, data: params.data },
            token,
          ).then(data => {
            //console.log(data)
            return Promise.resolve({ data })
          })
        })
      },
      /*delete: (collection, params) => {
        //console.log("delete", collection, params)
        return request(
          API_URL,
          "POST",
          "v0/pipelines/execute",
          {
            project: "blueforest:dropin/editor",
            id: "blueforest:dropin/ui/editor:v1:delete",
          },
          { collection, id: params.id },
          token,
        )
        //.then(() => ({ data: { id: params.id } }))
      },*/
      //deleteMany: () => Promise.resolve({}),
      //getManyReference: () => Promise.resolve({ data: [], total: 0 }),
      //updateMany: () => Promise.resolve({}),
    }}
    title=""
    disableTelemetry
    hasEdit={false}
    layout={lProps => <RA.Layout
      {...lProps}
      appBar={aProps => <MUI.AppBar {...aProps}>
        <MUI.Toolbar style={{ minHeight: 50 }}>
          <MUI.Typography variant="h6" children="drop'in - éditeur v0.4.4"/>
        </MUI.Toolbar>
      </MUI.AppBar>}
      sidebar={mProps => <Menu {...mProps} projects={data.projects}/>}
    />}
  >
    {data.projects.reduce((a, p) => a.concat(p.collections), []).map((collection, colIndex) => <RA.Resource
      key={colIndex}
      name={collection.id}
      options={{ label: collection.name }}
      list={props => <RA.List {...props}
        sort={collection.sort}
        hasCreate={collection.permissions.create}
        filters={[
          ...(typeof collection.search !== "undefined"
            ? [ <RA.TextInput label="Rechercher" source="search" alwaysOn/> ]
            : []),
        ]}
      >
        <RA.Datagrid
          rowClick="show"
          isRowSelectable={() => false}
          className="list-view"
          children={generateFields(Object.keys(collection.fields).reduce((result, field) => {
            if(collection.fields[field].list) {
              result[field] = collection.fields[field]
            }
            return result
          }, {}), false)}
        />
      </RA.List>}
      show={props => <RA.Show {...props}
        hasEdit={collection.permissions.update}
        actions={<ShowToolbar
          hasDelete={collection.permissions.delete}
          token={token}
          actions={collection.actions}
        />}
      >
        <RA.SimpleShowLayout
          basePath={props.basePath}
          children={generateFields(collection.fields, false)}
        />
      </RA.Show>}
      edit={props => <RA.Edit {...props}>
        <RA.SimpleForm
          toolbar={<SaveButtonToolbar/>}
        >
          <div
            style={{ width: "100%" }}
            children={generateFields(collection.fields, true)}
          />
        </RA.SimpleForm>
      </RA.Edit>}
      create={props => <RA.Create {...props}>
        <RA.SimpleForm
          toolbar={<SaveButtonToolbar/>}
        >
          <div
            style={{ width: "100%", display: "inline-block" }}
            children={generateFields(collection.fields, true)}
          />
        </RA.SimpleForm>
      </RA.Create>}
    />)}
  </RA.Admin>
}

export default App
