
export const request = (host, method, path, params = {}, body, token) => {
  let init = { method, headers: { "content-type": "application/json" } }
  if (typeof host.host !== "undefined") init.headers["Host"] = host.host
  if (typeof token !== "undefined") init.headers["Authorization"] = "Bearer " + token
  if (typeof body !== "undefined") init.body = JSON.stringify(body)

  let stringParams = ""
  const keys = Object.keys(params)
  if (keys.length !== 0) {
    stringParams = `?${keys.filter(k => typeof params[k] !== "undefined").map(k => k + "=" + params[k]).join("&")}`
  }

  return new Promise((resolve, reject) => {
    const url = `${host.url}/${path}${stringParams}`
    return fetch(encodeURI(url), init).then(result => {
      return result.text().then(text => {
        try {
          return JSON.parse(text)
        } catch (e) {
          return text
        }
      })
    }).then(result => {
      if (typeof result === "object" && typeof result.error !== "undefined") {
        reject(result.error)
      } else {
        resolve(result)
      }
    }, reject)
  })
}
